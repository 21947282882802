<template>
  <div class="success-page">
    <!-- <Sidebar /> -->
    <HeaderPage />
    <v-stepper v-model="stepper" flat style="margin-top:55px; width: 100%;padding-left:3%;padding-right:3%" >
      <v-stepper-header >
        <div class="d-flex flex-column align-center justify-center">
            <v-stepper-step
                :complete="stepper == 1"
                step="1"
                color="#ff0090"
            >
            </v-stepper-step>
            <div style="margin-top:-20%; max-width: 100xp">
                <small>{{$t('message.label-step-checkout')}}</small>
            </div>
        </div>
        <v-divider></v-divider>
        <div class="d-flex flex-column align-center justify-center" >
            <v-stepper-step
                step="2"
                color="#ff0090"
            >
            </v-stepper-step>
            <div style="margin-top:-20%; max-width: 100px">
                <small>{{$t('message.label-step-locker')}}</small>
            </div>
        </div>
        <v-divider></v-divider>
        <div class="d-flex flex-column align-center justify-center" >
            <v-stepper-step
                step="3"
                color="#ff0090"
            >
            </v-stepper-step>
            <div style="margin-top:-20%; max-width: 100px">
                <small>{{$t('message.label-step-success')}}</small>
            </div>
        </div>
      </v-stepper-header>
      <v-stepper-content step="3"></v-stepper-content>
    </v-stepper>
    <v-container>



      <div class="d-flex flex-column align-center">

        <div style="background-color:white;width:100%;margin-top: 15pt;padding: 30pt 5pt 30pt 5pt" class="justify-space-between">
          <div v-if="isLoading">
            <v-skeleton-loader
              v-bind="attrs"
              width="300"
              type="list-item-two-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
              v-bind="attrs"
              width="300"
              type="list-item-three-line"
            ></v-skeleton-loader>
            <v-skeleton-loader
              v-bind="attrs"
              type="image"
              height="35"
              class="ps-0 mt-1"
              style="margin-left: 20%"
              width="200"
            ></v-skeleton-loader>
          </div>
          <div v-else>
            <div class="d-flex justify-center align-center">
              <div class="d-flex flex-row justify-between">
                <div class="label-order">{{ $t("message.title-order-date") }}</div>
                <div class="value-order">
                  : {{ orderPaid.created_at }}
                  <!-- 2022-01-06 16:33:33 -->
                </div>
              </div>
            </div>

            <div class="d-flex justify-center align-center">
              <div class="d-flex flex-row justify-between">
                <div class="label-order-date">{{ $t("message.title-order-code") }}</div>
                <div class="value-order-date">
                  : {{ orderPaid.order_no }}
                  <!-- SO/20220106/XXII/I/0000272 -->
                </div>
              </div>
            </div>

          <div class="d-flex flex-column align-center justify-center" style="margin-top: 10pt">
            <div>{{ $t("message.title-thank-you-order") }}</div>
            <div>noreply@tajimingo.com</div>
            <div style="font-size:10pt">{{ $t("message.title-desc-copyright") }}</div>
          </div>
          <div class="d-flex flex-column align-center" style="margin-top: 20pt">
            <v-btn @click.prevent="$router.push('/')" width="200px" style="margin-bottom:2%; border-color:#ff0090; background-color:#ff0090; color:white;text-transform: none;" outlined>{{ $t("message.back-to-top") }}</v-btn>
          </div>
        </div>
      </div>
      </div>
    </v-container>
    <Sidebar />
  </div>
</template>

<script>
// import Sidebar from "@/components/Sidebar.vue";
import Sidebar from '../components/developmentv2/Sidebar.vue'
import HeaderPage from '../components/developmentv2/HeaderPage.vue'
import Skeleton from '../components/skeleton/Skeleton.vue';
export default {
  name: "Success",
  components: {
    Sidebar,
    Skeleton,
    HeaderPage
  },
  data() {
    return {
      stepper: 3,
      isLoading: false,
      attrs: {
        // height: 30,
        class: "mb-1",
        boilerplate: false,
      },
    };
  },
  mounted() {
    this.isSuccess();
  },
  created() {
    // this.isSuccess();
  },
  methods: {
    // NOTES: HIDE method getTodayDate
    // getTodayDate(){
    //   const minutes = ()=>{
    //     const a = new Date().getMinutes()
    //     if(a.length === 1){
    //       a = '0'+ a.toString()
    //     }
    //     return a
    //   }
    //   let date = new Date().getFullYear() + "/" + new Date().getMonth() + "/" + new Date().getDate() + " " + new Date().getHours() + ":" + minutes()
    //   return date
    // },
    async isSuccess() {
      this.isLoading = true
      let params = this.$router.history.current.name
      let payload_invoice = localStorage.getItem("invoice");
      if (params === "Success" && payload_invoice) {
        await this.$store.dispatch("order_module/fetchOrderPaid", {'invoice_no': payload_invoice});
      }
      this.isLoading = false
    },
  },
  computed: {
    orderPaid() {
      return this.$store.state.order_module.orderPaid
    }
  }
};
</script>

<style scoped>
/* @media (max-width: 360px) {
  .label-order-date {
  font-size: 12px;
  }
  .value-order-date {
    font-size: 10px;
    margin-left: 10px
  }
  .label-order {
    font-size: 12px;
  }
  .value-order {
    font-size: 10px;
    margin-left: 10px;
  }
} */
.label-order-date {
  font-size: 14px;
}
.value-order-date {
  font-size: 14px;
  margin-left: 10px
}
.label-order {
  font-size: 14px;
}
.value-order {
  font-size: 14px;
  margin-left: 10px;
}
.parent-class {
  height: 91vh !important;
}
.button-class {
  position: fixed;
  margin-bottom: 0px !important;
  height: 55px !important;
  bottom: 0px;
  color: white !important;
  border-radius: 0px !important;
  box-shadow: 0px !important;
}
.header-title {
  font-family: roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  height: 25px;
}
.v-stepper__header {
  box-shadow: none;
}
.success-page {
  background-color: #ff0090;
  height: 100vh;
  max-width: 100vw !important;
}
a{
  text-decoration: underline;
}
</style>
